import financeiro from './financeiro'
import cadastros from './cadastros'
import administracao from './administracao'
import mensagens from './mensagens'

export default [

  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },

  ...financeiro,...cadastros,...administracao,...mensagens

]
