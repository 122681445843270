<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--      <NotificationDropdown/>-->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <span
              class="user-status"
              v-if="user.tipo_usuario == 1"
            >
              Admin
            </span>
            <span
              class="user-status"
              v-else-if="user.empresaLogada"
            >
              {{ user.empresaLogada }}
            </span>
            <span class="user-status" v-else> Convidado </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="
              $store.state.user.user.url
                ? `https://smartprotese.s3.sa-east-1.amazonaws.com/smartprotese/${$store.state.user.user.url}`
                : ''
            "
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'alterar-foto-perfil' }"
        >
          <feather-icon size="16" icon="EditIcon" class="mr-50" />
          <span>Foto Perfil</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'alterar-senha-usuario' }"
        >
          <feather-icon size="16" icon="LockIcon" class="mr-50" />
          <span>Alterar Senha</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'logout' }"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BFormSelect,
  BCol,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
//import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import { mapState } from "vuex";

export default {
  components: {
    BLink,
    BBadge,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormSelect,
    BCol,
    vSelect,
    // Navbar Components
    DarkToggler,
    // NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      selected: this.selecionado,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  /* mounted() {
    this.$store.dispatch('user/listaUser')
  } */
};
</script>

<style >
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: rgb(222, 234, 249);
  border: none;
  color: #166bc0;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #717485;
}
.style-chooser .vs__state-active {
  color: #fff;
}
</style>
