export default [
    {
        title: 'Administração',
        icon: 'MonitorIcon',
        children: [
            {
                title: 'Clientes',
                route: 'adm-clientes',
                icon: 'UsersIcon',
                permissoes: 1
            },

        ],
    },
]
