export default [
    {
        header: 'Mensagens',
        icon: 'MailIcon',
        children: [
            {
                title: 'Enviar Mensagem',
                route: 'enviar-mensagem',
                icon: 'SendIcon',
                permissao: 1
            },
        ],
    },
]
